
* {
    margin: 0;
    padding: 0;
  }
  
  body {
    background: #637d63;
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .form {
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    margin-top: 30px;
  }
  
  .form .form__custom-button {
    margin-top: 50px;
  }

  .custom-column-header {
    font-size: 10px;
    font-weight: 'bold';
    color: #2F3746;
    font-family: 'Arial';
    line-height: 1;   /* Ajusta el line-height aquí */
  }
